import { Base64 } from "js-base64";
export default {
  emits: ["save"],
  props: {
    mbData: {
      type: Object,
      default: {
        mbmc: "",
        bz: "",
        mbfl: "",
        mbmc: "",
        id: -1,
        mbtext: ""
      }
    }
  },
  data() {
    return {
      writer: null,
      writerState: {
        printpreview: false
      },
      toolBtn: {
        size: 18,
        color: "#1590ff"
      },
      fontName: "宋体",
      fontSize: "12",
      fontColor: "#000000",
      backColor: "#ffffff",
      fontList: [{
        label: "宋体",
        value: "宋体"
      }, {
        label: "楷体",
        value: "楷体"
      }, {
        label: "黑体",
        value: "黑体"
      }, {
        label: "微软雅黑",
        value: "微软雅黑"
      }, {
        label: "微软正黑体",
        value: "微软正黑体"
      }, {
        label: "新宋体",
        value: "新宋体"
      }, {
        label: "仿宋",
        value: "仿宋"
      }, {
        label: "幼圆",
        value: "幼圆"
      }, {
        label: "隶书",
        value: "隶书"
      }, {
        label: "华文细黑",
        value: "华文细黑"
      }, {
        label: "华文楷体",
        value: "华文楷体"
      }, {
        label: "华文宋体",
        value: "华文宋体"
      }, {
        label: "华文中宋",
        value: "华文中宋"
      }, {
        label: "华文彩云",
        value: "华文彩云"
      }, {
        label: "华文琥珀",
        value: "华文琥珀"
      }, {
        label: "方正舒体",
        value: "方正舒体"
      }, {
        label: "方正姚体",
        value: "方正姚体"
      }],
      fontSizeList: [{
        value: "8",
        value: "8"
      }, {
        value: "9",
        value: "9"
      }, {
        value: "10",
        value: "10"
      }, {
        value: "11",
        value: "11"
      }, {
        value: "12",
        value: "12"
      }, {
        value: "14",
        value: "14"
      }, {
        value: "16",
        value: "16"
      }, {
        value: "18",
        value: "18"
      }, {
        value: "20",
        value: "20"
      }, {
        value: "22",
        value: "22"
      }, {
        value: "24",
        value: "24"
      }, {
        value: "26",
        value: "26"
      }, {
        value: "28",
        value: "28"
      }, {
        value: "36",
        value: "36"
      }, {
        value: "48",
        value: "48"
      }, {
        value: "72",
        value: "72"
      }],
      dataFieldChecked: null,
      //当前选中的数据项id
      dataFieldItemChecked: {},
      //当前选中的数据项对象
      customFieldChecked: null,
      customFieldItemChecked: {},
      customFieldList: [//todo 这里数据需要后台传
      ],
      dataFieldList: [//todo 这里数据需要后台传
      ],
      needDialog: ["InsertImage", "InsertHorizontalLine", "ParagraphFormat", "Table_SplitCellExt", "Font", "FileOpen", "InsertCheckBoxOrRadio"],
      dateFormatList: ["当天日期", "YYYY年MM月DD日", "YYYY-MM-DD"],
      //todo 中文大写日期暂时没找到js格式化的方法，先不管了
      dateFieldChecked: '',
      xml: ""
    };
  },
  methods: {
    initWriter() {
      let newCtl = document.getElementById("myWriterControl");
      window.CreateWriterControlForWASM(newCtl);
      this.writer = newCtl;
      if (this.xml === "") {
        this.writer.DCExecuteCommand("ClearBodyContent", false, null);
      } else {
        this.writer.LoadDocumentFromString(this.xml);
      }
      window.WriterControl_EventElementMouseClick = (e, a) => {
        let font = this.writer.getFontObject();
        this.fontName = font.FontFamily;
        this.fontSize = font.FontSize;
      };
      newCtl.ondocumentdblclick = e => {
        let result = this.writer.CurrentInputField();
        if (result) {
          let currentinputfield = this.writer.GetElementProperties(result);
          let attr = currentinputfield.Attributes;
          if (attr) {
            switch (attr.DataType) {
              case '数据项':
                this.dataFieldChecked = attr.DataValue;
                this.$refs.dataFieldDialog.visible = true;
                break;
              case '自定义项':
                this.customFieldChecked = attr.DataValue;
                this.$refs.customDataDialog.visible = true;
                break;
              case '日期数据':
                this.dateFieldChecked = attr.DataValue;
                this.$refs.dateFieldDialog.visible = true;
                break;
              default:
                break;
            }
          }
        }
      };
    },
    commandHandler(command) {
      switch (command) {
        case "Save":
          let mbResult = {
            ...this.mbData
          };
          mbResult.mbtext = this.writer.SaveDocumentToBase64String('xml');
          this.$emit("save", mbResult);
          break;
        case "pageSet":
          this.writer.DocumentSettingsDialog(); //
          //this.loadXml();
          break;
        case "printpreview":
          this.writer.LoadPrintPreview();
          this.writerState.printpreview = true;
          break;
        case "closeprintpreview":
          this.writer.ClosePrintPreview();
          this.writerState.printpreview = false;
          break;
        case "print":
          this.writer.PrintDocument();
          break;
        case "insertTable":
          this.writer.insertTableDialog();
          break;
        case "AlignType":
          this.$refs.dialog.visible = true;
          break;
        case "InsertDataField":
          this.$refs.dataFieldDialog.visible = true;
          break;
        case "InsertDateDataField":
          this.$refs.dateFieldDialog.visible = true;
          break;
        case "InsertCustomDataField":
          this.$refs.customDataDialog.visible = true;
          break;
        case "Font":
          let fontObj = this.writer.getFontObject();
          this.writer.setFontObject(fontObj);
          this.writer.DCExecuteCommand(command, true, null);
          break;
        case "Color":
          this.$refs.fontColor.show();
          break;
        case "BackColor":
          this.$refs.backColor.show();
          break;
        default:
          if (this.needDialog.indexOf(command) > -1) {
            this.writer.DCExecuteCommand(command, true, null);
          } else {
            this.writer.DCExecuteCommand(command, false, null);
          }
          break;
      }
    },
    insertDataField() {
      let match = this.dataFieldList.filter(e => {
        return this.dataFieldChecked == e.id;
      });
      if (match && match.length > 0) {
        this.dataFieldItemChecked = match[0];
        let id = new Date().getTime() + Math.random() + "_" + this.dataFieldItemChecked.id;
        let options = {
          ID: id,
          InnerEditStyle: "Text",
          InnerText: this.dataFieldItemChecked.itemName,
          BackgroundText: this.dataFieldItemChecked.itemName,
          Attributes: {
            DataType: '数据项',
            DataValue: this.dataFieldItemChecked.id,
            DataColMap: this.dataFieldItemChecked.mapColName,
            DataModelMap: this.dataFieldItemChecked.mapModelName
          }
        };
        this.writer.DCExecuteCommand('DeleteField', false, null); //先删除当前光标的输入域，再插入一个新的
        this.writer.DCExecuteCommand("InsertInputField", false, options);
        this.$refs.dataFieldDialog.visible = false;
        this.dataFieldChecked = null;
        this.dataFieldItemChecked = {};
      } else {
        this.$message.warning("请选择要插入的数据项");
      }
    },
    insertCustomDataField() {
      let match = this.customFieldList.filter(e => {
        return this.customFieldChecked == e.id;
      });
      if (match && match.length > 0) {
        this.customFieldItemChecked = match[0];
        let id = new Date().getTime() + Math.random() + "_" + this.customFieldItemChecked.id;
        let options = {
          ID: id,
          InnerEditStyle: "Text",
          InnerText: this.customFieldItemChecked.itemName,
          BackgroundText: this.customFieldItemChecked.itemName,
          Attributes: {
            DataType: '自定义项',
            DataValue: this.customFieldItemChecked.bh
          }
        };
        this.writer.DCExecuteCommand('DeleteField', false, null); //先删除当前光标的输入域，再插入一个新的
        this.writer.DCExecuteCommand("InsertInputField", false, options);
        this.$refs.customDataDialog.visible = false;
        this.customFieldChecked = null;
        this.customFieldItemChecked = {};
      } else {
        this.$message.warning("请选择要插入的关键词");
      }
    },
    insertDateField() {
      if (this.dateFieldChecked) {
        let id = new Date().getTime() + Math.random() + "_date" + this.dateFieldChecked;
        let options = {
          ID: id,
          InnerEditStyle: "Text",
          InnerText: '日期',
          BackgroundText: '日期',
          Attributes: {
            DataType: '日期数据',
            DataValue: this.dateFieldChecked
          }
        };
        this.writer.DCExecuteCommand('DeleteField', false, null); //先删除当前光标的输入域，再插入一个新的
        this.writer.DCExecuteCommand("InsertInputField", false, options);
        this.$refs.dateFieldDialog.visible = false;
        this.dateFieldChecked = '';
      } else {
        this.$message.warning("请选择要插入的日期格式");
      }
    },
    cancelDataDialog() {
      this.$refs.dataFieldDialog.visible = false;
      this.dataFieldChecked = null;
      this.dataFieldItemChecked = {};
    },
    cancelCustomDataDialog() {
      this.$refs.customDataDialog.visible = false;
      this.customFieldChecked = null;
      this.customFieldItemChecked = {};
    },
    cancelDateDialog() {
      this.dateFieldChecked = "";
      this.$refs.dateFieldDialog.visible = false;
    },
    showCustomFieldDetail() {
      let match = this.customFieldList.filter(e => {
        return this.customFieldChecked == e.id;
      });
      if (match && match.length > 0) {
        this.customFieldItemChecked = match[0];
        this.$refs.customDataDialogDetail.visible = true;
      } else {
        this.$message.warning("请选择要查看的关键词");
      }
    },
    fontNameChange() {
      this.writer.DCExecuteCommand("FontName", false, this.fontName);
    },
    fontSizeChange() {
      this.writer.DCExecuteCommand("FontSize", false, this.fontSize);
    },
    fontColorChange(color) {
      this.writer.DCExecuteCommand("Color", false, color);
    },
    backColorChange(color) {
      this.writer.DCExecuteCommand("BackColor", false, color);
    },
    // 获取自定义项
    loadCommonItemData() {
      this.$axios.get("/api/Business/GetCommonitem", {}).then(res => {
        if (res.status == 200) {
          this.customFieldList.length = 0;
          this.customFieldList.push(...res.data.result);
        }
      }).catch();
    },
    // 获取数据项
    loadDataItemData() {
      this.$axios.get("/api/Business/GetDataitem", {}).then(res => {
        if (res.status == 200) {
          this.dataFieldList.length = 0;
          this.dataFieldList.push(...res.data.result);
        }
      }).catch();
    }
  },
  mounted() {
    this.initWriter();
  },
  created() {
    if (this.mbData && this.mbData.mbtext) {
      this.xml = Base64.decode(this.mbData.mbtext);
    }
    this.loadDataItemData();
    this.loadCommonItemData();
  },
  computed: {}
};